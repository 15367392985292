<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="mx-auto">
          <v-card-title>
            Historique : 
          </v-card-title>

          <v-card-text class="pt-4"
            >Consulter et répondre à toutes les demandes de  renseignement 
          </v-card-text>

          <v-divider></v-divider>
          <v-virtual-scroll
            :items="this.$store.getters['Auth/getListMessage']"
            :item-height="50"
            height="400"
          >
            <template v-slot="{ item }">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon large>mdi-mail</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.senderFullName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.senderEmail }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn @click="showMess(item)" depressed small>
                    <v-icon color="orange darken-4" right>mdi-email-box</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-alert v-model="info" text color="prmary">
          <div>
            <v-icon color="green">mdi-cirle</v-icon>
          </div>
          <h3 class="headline">Objet du message : {{ showMessage.object }}</h3>
          <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
          <div class="text-truncate">Description du message : {{ showMessage.content }}</div>

          <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
          <div>Date/heure de réception du message : {{ showMessage.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss") }}</div>
          <v-row align="center" no-gutters>
            <v-col class="grow">
              Nom & prénoms de l’expéditeur : {{ showMessage.senderFullName }} <br />
              Email  de l’expéditeur : {{ showMessage.senderEmail }}
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink">
              <v-btn @click="info = !info" color="red" outlined>
                Fermer
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-card v-if="info">
          <v-card-text>
            <v-text-field
              filled
              label="Saisir l’objet de la réponse au message"
              v-model="message.object"
              persistent-hint
            ></v-text-field>
            <v-textarea
              v-model="message.content"
              filled
              name="input-7-4"
              label="Saisir le contenu de la réponse au message"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="sendReply(showMessage.id)" color="primary" right>
              <v-icon class="mr-2">mdi-email-send</v-icon>Envoyer la réponse au message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    info: false,
    showMessage: [],
    message: {
      object: "",
      content: "",
      sender_email: "",
      sender_full_name: ""
    },
    password: "Password",
    bread: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Messagerie",
        disabled: true
      }
    ],
    show: false,
    marker: true,
    iconIndex: 0,
    icons: [
      "mdi-emoticon",
      "mdi-emoticon-cool",
      "mdi-emoticon-dead",
      "mdi-emoticon-excited",
      "mdi-emoticon-happy",
      "mdi-emoticon-neutral",
      "mdi-emoticon-sad",
      "mdi-emoticon-tongue"
    ],
    colors: [
      "#2196F3",
      "#90CAF9",
      "#64B5F6",
      "#42A5F5",
      "#1E88E5",
      "#1976D2",
      "#1565C0",
      "#0D47A1",
      "#82B1FF",
      "#448AFF",
      "#2979FF",
      "#2962FF"
    ],
    names: ["Ibrahima", "Soda", "Fanta"],
    surnames: ["Ndiaye", "Diop", "Seck"]
  }),

  computed: {
    icon() {
      return this.icons[this.iconIndex];
    },
    items() {
      const namesLength = this.names.length;
      const surnamesLength = this.surnames.length;
      const colorsLength = this.colors.length;

      return Array.from({ length: 10000 }, () => {
        const name = this.names[this.genRandomIndex(namesLength)];
        const surname = this.surnames[this.genRandomIndex(surnamesLength)];

        return {
          color: this.colors[this.genRandomIndex(colorsLength)],
          fullName: `${name} ${surname}`,
          initials: `${name[0]} ${surname[0]}`
        };
      });
    }
  },
  created() {
    this.loadAllMessage();
  },
  methods: {
    ...mapActions({
      send_message_reply: "Auth/send_message_reply",
      loadAllMessage: "Auth/loadAllMessage"
    }),
    showMess(item) {
      this.info = true;
      this.showMessage = item;
    },
    sendReply(item) {
      console.log(item);
      const data = new FormData();
      data.append("object", this.message.object);
      data.append("message_id", item);
      data.append("content", this.message.content);
      this.send_message_reply(data);
      this.message.object = "";
      this.message.content = "";
    },
    genRandomIndex(length) {
      return Math.ceil(Math.random() * (length - 1));
    }
  }
};
</script>
